import React from 'react'

export const Services = () => {
  return (
    <>
        <section className="pt-28 pb-36 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="mb-20 flex flex-wrap items-end -m-8">
            <div className="w-full md:w-1/2 p-8">
              <h2 className="text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight md:max-w-xl">Sobre nuestros servicios</h2>
            </div>
            <div className="w-full md:w-1/2 p-8">
              <p className="text-lg text-gray-900 font-medium leading-relaxed md:max-w-lg">Campaña Latú es una consultora odontológica dedicada a brindar servicios de excelencia en higiene y salud dental. Con años de experiencia en el sector, nuestro equipo de profesionales altamente capacitados se compromete a ofrecer un trato amable y soluciones efectivas para el bienestar bucal de nuestros pacientes.</p>
            </div>
          </div>
          <div className="flex flex-wrap -m-3">
            <div className="w-full md:w-1/3 p-3">
              <a className="flex justify-center md:block h-full" href="/#">
                <div className="relative h-full rounded-3xl overflow-hidden">
                  <img className="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000" src="src/images/teeth-cleaning.webp" alt="" />
                  <div className="absolute bottom-0 left-0 w-full px-11 pb-10">
                    <div className="px-8 py-4 bg-white rounded-xl shadow-5xl bg-opacity-5" style={{ WebkitBackdropFilter: 'blur(5px)', backdropFilter: 'blur(5px)' }}>
                      <h3 className="text-lg text-center font-semibold text-cyan-600">Limpieza dental</h3>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-full md:w-1/3 p-3">
              <a className="flex justify-center md:block h-full" href="/#">
                <div className="relative h-full rounded-3xl overflow-hidden">
                  <img className="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000" src="src/images/fluorizacion-dental.jpg" alt="" />
                  <div className="absolute bottom-0 left-0 w-full px-11 pb-10">
                    <div className="px-8 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl" style={{ WebkitBackdropFilter: 'blur(5px)', backdropFilter: 'blur(5px)' }}>
                      <h3 className="text-lg text-center font-semibold text-cyan-600">Fluorización dental</h3>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="w-full md:w-1/3 p-3">
              <a className="flex justify-center md:block h-full" href="/#">
                <div className="relative h-full rounded-3xl overflow-hidden">
                  <img className="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000" src="src/images/Sellantes-dentales.webp" alt="" />
                  <div className="absolute bottom-0 left-0 w-full px-11 pb-10">
                    <div className="px-8 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl" style={{ WebkitBackdropFilter: 'blur(5px)', backdropFilter: 'blur(5px)' }}>
                      <h3 className="text-lg text-center font-semibold text-cyan-600">Sellantes Dentales</h3>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
