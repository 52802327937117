import './App.css';
import './Stylesheets/tailwind/tailwind.min.css';
import {Header} from './components/Header.js';
import {Footer} from './components/Footer.js';
import { Hero } from './views/Home/Hero.js';
import { Card } from './views/Home/Card.js';
import { Services } from './views/Home/Services.js';
import { Valorations } from './views/Home/Valorations.js';
import { Contact } from './views/Home/Contact.js';
import FadeInSection from './hooks/FadeInSection';

function App() {
  return (
    <div className="App">
      <section>
        <Header />
        <Hero />
      </section>
      <FadeInSection>
        <Card />
      </FadeInSection>
      <FadeInSection>
        <Services />
      </FadeInSection>
      <FadeInSection>
        <Valorations />
      </FadeInSection>
      <FadeInSection>
        <Contact />
      </FadeInSection>
      <Footer />
    </div>
  );
}

export default App;
