import React from 'react'

export const Contact = () => {
  return (
    <>
      <section className="pt-24 pb-36 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="mb-20 text-center md:max-w-xl mx-auto">
            <h2 className="mb-4 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">Contacto</h2>
            <p className="text-lg text-gray-600 font-medium leading-normal md:max-w-lg mx-auto">¿Tiene preguntas o desea agendar una cita? Estamos aquí para ayudarle. Complete nuestro formulario de contacto, llámenos o visítenos en nuestra oficina. En Campaña Latú, su salud dental es nuestra prioridad.</p>
          </div>
          <div className="flex flex-wrap xl:items-center -m-8">
            <div className="w-full md:w-1/2 p-8">
              <div className="max-w-max mx-auto overflow-hidden rounded-3xl">
                <div class="gmap_canvas">
                  <iframe 
                    title="location_map"
                    width="600" 
                    height="600" 
                    id="gmap_canvas" 
                    src="https://maps.google.com/maps?q=Ovalle&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                    frameborder="0"
                    marginheight="0" 
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-8">
              <p className="mb-4 text-sm font-bold uppercase tracking-px">Email</p>
              <ul className="mb-12">
                <li className="text-lg text-gray-600 font-medium leading-normal">contacto@email.com</li>
                <li className="text-lg text-gray-600 font-medium leading-normal">soporte@gmail.com</li>
              </ul>
              <p className="mb-4 text-sm font-bold uppercase tracking-px">Telefono</p>
              <ul className="mb-14">
                <li className="text-lg text-gray-600 font-medium leading-normal">+56 9 9123 4567</li>
                <li className="text-lg text-gray-600 font-medium leading-normal">+56 9 8123 4567</li>
              </ul>
              <p className="mb-4 text-sm font-bold uppercase tracking-px">Dirección</p>
              <ul>
                <li className="text-lg text-gray-600 font-medium leading-normal">1020 Av. Falsa, San Falso Distrito. Chile</li>
                
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
