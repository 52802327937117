import React from 'react'

export const Valorations = () => {
  return (
    <>
        <section className="py-24 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -m-8">
            <div className="w-full md:w-1/3 p-8">
              <div className="md:max-w-xs mx-auto text-center">
                <div className="flex justify-center mb-6 -m-1">
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 text-2xl font-semibold leading-snug">
                  <span>“Un excelente trabajo en la atención al cliente.”</span>
                  </h3><h3>
                    </h3>
      
      <p className="text-gray-600 font-medium">Eduardo Araya</p>
                  
                
              </div>
            </div>
            <div className="w-full md:w-1/3 p-8">
              <div className="md:max-w-xs mx-auto text-center">
                <div className="flex justify-center mb-6 -m-1">
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 text-2xl font-semibold leading-snug">
                  <span>“Buen trato del personal, y en el seguimiento de mi tratamiento”</span>
                  </h3><h3>
                    </h3>
      
      <p className="text-gray-600 font-medium">Fernando Gutierrez</p>
                  
                
              </div>
            </div>
            <div className="w-full md:w-1/3 p-8">
              <div className="md:max-w-xs mx-auto text-center">
                <div className="flex justify-center mb-6 -m-1">
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                  <div className="w-auto p-1">
                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0L15.708 7.512L24 8.7168L18 14.5656L19.416 22.824L12 18.9264L4.584 22.824L6 14.5656L0 8.7168L8.292 7.512L12 0Z" fill="#4F46E5"></path>
                    </svg>
                  </div>
                </div>
                <h3 className="mb-4 text-2xl font-semibold leading-snug">
                  <span>“Muy responsable y puntuales con las citas”</span>
                  </h3><h3>
                    </h3>
      
      <p className="text-gray-600 font-medium">Andres Parra</p>
                  
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
