import React from 'react'

export const Card = () => {
  return (
    <>
        <section class="py-36 bg-blueGray-50 overflow-hidden">
            <div class="container px-4 mx-auto">
            <div class="flex flex-wrap lg:items-center -m-8 lg:-m-14">
                <div class="w-full md:w-1/2 p-8 lg:p-14">
                <div class="relative max-w-max mx-auto lg:ml-auto lg:mr-0 overflow-hidden rounded-4xl">
                    <img class="transform hover:scale-105 transition ease-in-out duration-1000" src="src/flaro-assets/images/team/bg3.png" alt="" />
                </div>
                </div>
                <div class="w-full md:w-1/2 p-8 lg:p-14">
                <div class="md:max-w-sm">
                    <p class="mb-8 font-sans text-sm text-indigo-600 font-semibold uppercase tracking-px">👋 Hola a todos</p>
                    <h2 class="mb-8 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">Andrea Campaña</h2>
                    <p class="mb-7 text-lg text-gray-900 font-medium text-justify">
                        La Dra. Andrea Campaña es una destacada especialistas en el área de la salud dental, 
                        con una sólida trayectoria en odontología preventiva y estética. Graduada con honores de la Universidad de Valparaiso, 
                        ha dedicado más de cinco años a perfeccionar sus habilidades y conocimientos en el cuidado dental. 
                        Su enfoque meticuloso y su pasión por la salud bucal la han convertido en una figura respetada tanto por sus colegas como por sus pacientes.
                    </p>
                    <p class="text-gray-600 font-medium">'La sonrisa es el mejor punto de entrada en una nueva relación personal'</p>
                </div>
                </div>
            </div>
            </div>
        </section>
    </>
  )
}
