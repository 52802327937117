import React from 'react'

export const Hero = () => {
  return (
    <>
  <div class="overflow-hidden pb-16">
    <div class="container px-4 mx-auto">
      <div class="mb-16 relative bg-indigo-50 overflow-hidden rounded-3xl">
        <img class="absolute left-0 bottom-0" src="src/flaro-assets/images/headers/gradient3.svg" alt="" />
        <div class="relative z-20 flex flex-wrap items-end -m-8">
          <div class="w-full lg:w-1/2 p-20 lg:pr-0 lg:pl-28 lg:py-28">
            <h2 class="mb-7 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">Especilista odontologica Andrea Campaña</h2>
            <p class="mb-10 text-lg text-gray-900 font-medium">Servicios odondologicos y designativos en la ciudad de Ovalle</p>
            <div class="mb-6 md:inline-block">
              <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Agenda tu cita Aquí</button>
            </div>
            <ul>
              <li class="inline-flex text-sm text-gray-500 font-medium">Consulta sin cargo</li>
              <li class="inline-flex text-black px-1">•</li>
              <li class="inline-flex text-sm text-gray-500 font-medium">Diferentes metodos de pago</li>
            </ul>
          </div>
          <div class="w-full lg:w-1/2 p-8">
            <img src="https://static.shuffle.dev/uploads/files/92/92f74c3773df040ae92b7f4a1070de3abb35bd92/ilustracion-concepto-cuidado-dental-dibujos-animados-23-2148986596-removebg-preview-png-1721189777019.webp" alt="" />
          </div>
        </div>
      </div>
      <p class="mb-9 text-gray-900 text-center font-medium">Trabajamos con las siguientes coberturas</p>
      <div class="flex flex-wrap justify-center items-center -m-8">
        <div class="w-auto p-8">
          <img src="src/flaro-assets/logos/brands/brand-xl.png" alt="" />
        </div>
        <div class="w-auto p-8">
          <img src="src/flaro-assets/logos/brands/brand-xl-2.png" alt="" />
        </div>
        <div class="w-auto p-8">
          <img src="src/flaro-assets/logos/brands/brand-xl-3.png" alt="" />
        </div>
        <div class="w-auto p-8">
          <img src="src/flaro-assets/logos/brands/brand-xl-4.png" alt="" />
        </div>
        <div class="w-auto p-8">
          <img src="src/flaro-assets/logos/brands/brand-xl-5.png" alt="" />
        </div>
      </div>
    </div>
  </div>
    </>
  )
}
