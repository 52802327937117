import React from 'react';
import { motion } from 'framer-motion';
import useFadeIn from './useFadeIn'; // Asegúrate de ajustar la ruta según la ubicación de tu hook

const FadeInSection = ({ children }) => {
    const [ref, controls, fadeInVariants] = useFadeIn();

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={fadeInVariants}
        >
            {children}
        </motion.div>
    );
};

export default FadeInSection;